$count: 10;

@for $i from 0 through $count {
  .opacity-#{$i} {
    @if ($i == 10) {
      opacity: 1;
    } @else {
      opacity: #{$i/10};
    }
  }
}
