$width: 18px;
$width-lg: 22px;

@mixin custom-checkradio($size: "sm") {
  display: flex;
  border: 2px solid $gray-400;
  position: absolute;
  left: 0;
  top: $font-size-base/10;

  @if ($size == "sm") {
    padding: $font-size-base / 2;
  } @else {
    padding: $font-size-base / 1.5;
  }
}

@mixin custom-checkradio-point($size: "sm", $type: "checkbox") {
  @if ($size == "sm") {
    left: 4px;
    top: 4px;
  } @else {
    left: 7px;
    top: 6px;
  }
}

// positions label according to size and
// block level of checkbox
@mixin custom-checkradio-label($size: "sm", $inline: false) {
  $left-amount: 0;
  @if ($size == "sm") {
    $left-amount: $font-size-base / 2;
  }
  @if ($size == "lg") {
    $left-amount: $font-size-base;
  }
  @if ($inline) {
    $left-amount: $left-amount + $font-size-base;
  }

  margin-left: $left-amount;
}

// creates consistent checkmark and
// positions according to checkbox size
@mixin create-checkmark($size: "sm") {
  width: $font-size-base/2.2;
  height: $font-size-base/1.3;
  border: solid $blue;
  padding: 0;
  border-radius: 0;
  background: transparent;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);

  @if ($size == "sm") {
    top: 0;
    left: $font-size-base/3;
  }
  @if ($size == "lg") {
    top: $font-size-base/5;
    left: $font-size-base/2.1;
  }
}

.form-check {
  //inline styles
  &#{&}-inline {
    .form-check-label {
      @include custom-checkradio-label("sm", true);
    }
  }

  //lg styles
  &#{&}-lg {
    margin-bottom: $font-size-base / 2;
    margin-right: $font-size-base;

    .form-check-label {
      @include custom-checkradio-label("lg");
    }
    &.form-check-inline {
      .form-check-label {
        @include custom-checkradio-label("lg", true);
      }
    }

    input {
      + span {
        @include custom-checkradio("lg");
      }

      &:checked[type="radio"] + span::after {
        @include custom-checkradio-point("lg", "radio");
      }

      &:checked[type="checkbox"] + span::after {
        @include custom-checkradio-point("lg", "checkbox");
      }

      &:checked[type="checkbox"] + span::after {
        @include create-checkmark("lg");
      }
    }
  }

  &-label {
    @include custom-checkradio-label();
  }

  //disabled styles
  &.disabled {
    opacity: 0.6;
    &:hover {
      cursor: not-allowed;
    }
  }

  span {
    transition: all 0.2s;
    &::after {
      transition: all 0.2s;
    }
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;

    + span {
      @include custom-checkradio();
    }

    &[type="checkbox"] + span {
      border-radius: $border-radius-sm;
    }

    &[type="radio"] + span {
      border-radius: 50%;
    }

    &:checked + span {
      border-color: $blue;
      &::after {
        content: "";
        padding: 3px;
        background-color: $blue;
        position: absolute;
        border-radius: 50%;
      }
    }

    &:checked[type="radio"] + span::after {
      @include custom-checkradio-point("sm", "radio");
    }

    &:checked[type="checkbox"] + span::after {
      @include create-checkmark("sm");
    }
  }
}
