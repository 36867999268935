.JTCustomProgress {
  &__list {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;

    li:last-child {
      &::before {
        padding: 0;
        background-color: transparent;
      }
    }
  }

  &__step.active {
    &::before {
      background-color: $primary;
    }
    .JTCustomProgress__goal {
      color: $primary;
      border: 2px solid $primary;
      svg {
        path {
          fill: $primary;
        }
      }
    }
  }

  &__step.current {
    font-weight: $font-weight-semi-bold;
    &::before {
      background-color: gray;
    }
  }
}

@mixin progress-size($class: "sm", $size: 2rem) {
  $size: $size;
  .JTCustomProgress {
    &__step--#{$class} {
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &::before {
        content: "";
        width: calc(100%);
        padding: 1px;
        background-color: gray;
        position: absolute;
        left: calc(50% + #{$size / 2});
        top: calc(#{$size} / 2);
        transition: all 0.2s;
      }

      .JTCustomProgress__goal {
        height: $size;
        width: $size;
        border: 1px solid gray;
        color: gray;
        transition: all 0.2s;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $white;
      }
    }
  }
}

@include progress-size(sm, 2rem);
@include progress-size(md, 4rem);
@include progress-size(lg, 6rem);
@include progress-size(xs, 1.3rem);
