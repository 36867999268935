@import "./../../_custom-utilities/text";

.JTRadioSelect {
  border-radius: $border-radius;
  border: 2px solid $gray-200;
  transition: border 0.2s;
  padding-left: 0 !important;

  label {
    height: 100%;
    width: 100%;
    padding: 0.75rem 1rem 0.75rem 3.25rem;
    &:hover {
      cursor: pointer;
    }
  }

  &.active,
  &:hover,
  &:focus {
    border-color: $blue;
    border-left-width: $font-size-base/2;
  }

  &.disabled {
    &:hover,
    &:focus,
    label {
      border-color: $gray-200;
      border-left-width: 2px;
      cursor: not-allowed;
    }
  }

  input + span {
    top: calc(50% - #{$font-size-base/1.5});
    left: 1rem;
  }

  &--minimal {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 2px solid $gray-200;
    border-radius: 0;

    &.active {
      background-color: $light-primary;
    }
    input + span {
      top: unset;
    }
  }
}
