.nav-tabs.nav-tabs-underline {
  .nav-link {
    color: $body-color;
    border: 0;
    border-bottom: 3px solid transparent;

    &.active {
      border-color: $blue;
      border-right-color: transparent;
      border-left-color: transparent;
      border-top-color: transparent;
    }
  }
}
