@mixin link-hover {
  color: $primary;
  text-decoration: none;
}

jt-navbar {
  min-height: 71px;
  background-color: $primary;
  display: block;

  .dropdown-toggle::after {
    display: none;
  }
  .fal.fa-angle-down {
    font-size: 1.2rem;
    margin-left: 0.5rem;
  }
  .navbar {
    z-index: 1040;
  }
  .navbar-nav {
    .btn {
      font-size: $font-size-base;
    }
    .dropdown-toggle::after {
      margin-top: -3px;
    }
  }

  .sub-navbar {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .dropdown-menu {
      transform: translateX(-2px);
      min-width: 300px;
    }

    .nav-item,
    .menu-text {
      color: #7e8299;
      font-weight: 500;

      &:hover,
      &:focus {
        @include link-hover;
      }
    }
  }

  a,
  a.dropdown-item,
  .dropdown-item {
    &:hover,
    &:focus {
      @include link-hover;
    }
  }
}

jt-sub-nav {
  .navbar {
    z-index: 90;
  }

  @media screen and (min-width: 768px) and (max-width: 820px) {
    .nav-link {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
  }
}

.jt-side-menu {
  &__menu {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 9999;
    background-color: white;
    transform: translateX(-100%);
    transition: transform 0.3s;
    width: 65%;
    height: 100vh;

    &--opened {
      transform: translateX(0);
    }

    ul {
      ul {
        .nav-link {
          padding-left: 2rem;
        }
        ul {
          .nav-link {
            padding-left: 4rem;
          }
        }
      }
    }

    .nav-link {
      &:focus,
      &:hover,
      &[aria-expanded="true"] {
        background-color: #f3f6f9;
      }
    }
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: black;
    opacity: 0.4;
    z-index: 9990;
    display: none;

    &--shown {
      display: block;
    }
  }
}
