$border: 2px;
$height: 1.5rem;
$arrow-width: 5px;
$offset-height: 4%;

//@mixin hide-arrow() {
//  content: "";
//  height: 0;
//  width: 0;
//  border: 0;
//}

@mixin create-clip($size) {
  .arrow-progress--size-#{$size} {
    .arrow-progress__step {
      &--nose {
        clip-path: polygon(
          0% 0%,
          0% 100%,
          #{100 - $size}% 100%,
          100% 50%,
          #{100 - $size}% 0%
        );
      }

      &--tail {
        clip-path: polygon(0% 0%, #{$size}% 50%, 0% 100%, 100% 100%, 100% 0%);
      }

      &--tail-nose {
        clip-path: polygon(
          0% 0%,
          #{$size}% 50%,
          0% 100%,
          #{100 - $size}% 100%,
          100% 50%,
          #{100 - $size}% 0%
        );
      }
    }
  }
}

@mixin arrow($color) {
  //border: $border solid $color;
  height: $height;
  display: flex;
  border-radius: 5px;
  overflow: hidden;

  .arrow-progress {
    &__step {
      height: calc(#{100% + $offset-height});
      flex: 1;
      position: relative;
      background-color: $gray-200;

      //&:last-of-type::after {
      //  @include hide-arrow();
      //}
      //
      //&::after {
      //  content: "";
      //  height: calc(#{$height} - #{$arrow-width} - #{$border});
      //  width: calc(#{$height} - #{$arrow-width} - #{$border});
      //  border: $arrow-width solid $color;
      //  position: absolute;
      //  right: $arrow-width;
      //  top: calc(#{$border}/2);
      //  border-bottom-color: transparent;
      //  border-left-color: transparent;
      //  transform: rotate(45deg);
      //
      //}

      &--fill {
        background-color: $color;

        //&::after {
        //  @include hide-arrow();
        //}
      }
    }
  }
}

.arrow-progress {
  @include arrow($blue-60);
}

.disabled.arrow-progress {
  @include arrow($gray-500);
}
@for $i from 1 through 15 {
  @include create-clip($i);
}
